import styled, { css } from "styled-components";
import Nav from "react-bootstrap/Nav";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import logo from "../../assets/logo-animated.gif";
import { siteDarkBlue, sitePurple } from "../../providers/Theme";
import NavDropdown from "react-bootstrap/NavDropdown";

const NavTextStyles = (color: string) => css`
  color: ${color}!important;

  &.active {
    background-color: ${color}!important;
    color: white !important;
  }

  &:active {
    background-color: ${color}!important;
    color: white !important;
  }
`;

const SharedNavStyles = (color: string) => css`
  position: relative;
  z-index: 1;
  text-align: center;

  &:hover {
    &:after {
      content: "";
      position: absolute;
      left: 0%;
      right: 0;
      margin: auto;
      bottom: 0;
      width: 50%;
      border-bottom: 2px solid ${color}!important;
    }
  }
`;

export const NavItem = styled(Nav.Item)`
  font-size: 1.37rem;
  font-weight: bold;

  ${({ theme }) => theme.lg`
    max-width: 228px;
  `}
`;

export const DonateBreadcrumb = styled.h5`
  margin-left: auto;
  font-weight: 900;
  color: ${sitePurple};
  cursor: pointer;
  margin-bottom: 0;

  ${({ theme }) => theme.lg`
    display: none;
  `}

  &:hover {
    text-decoration: underline;
  }
`;

interface NavLinkProps {
  color: string;
}

export const NavLink = styled(Nav.Link)<NavLinkProps>`
  ${props => NavTextStyles(props.color)};
  ${props => SharedNavStyles(props.color)};
`;

export const StyledNavDropdown = styled(NavDropdown)<NavLinkProps>`
  ${props => SharedNavStyles(props.color)};

  .nav-link {
    ${props => NavTextStyles(props.color)};
  }

  .dropdown-menu a {
    font-weight: bold;
    ${props => NavTextStyles(props.color)};
  }

  // Show drop down on hover. Note: renderMenuOnMount={true} needed on component
  &:hover {
    .dropdown-menu {
      display: block;
    }
  }

  .dropdown-menu {
    text-align: center;
    border: none;
    width: auto;
    min-width: 100%;
    padding: 5px 10px;
  }

  ${({ theme }) => theme.lg`
    .dropdown-menu {
      border: 1px solid rgba(0,0,0,.15);

      left: 50%;
      right: auto;
      text-align: center;
      transform: translate(-50%, 0);
    }
  `}
`;

export const Logo = styled.img.attrs({
  alt: "header logo",
  src: logo,
  width: "250",
  height: "150",
  className: "d-inline-block align-top"
})`
  cursor: pointer;
`;

export const BreadcrumbItem = styled(Breadcrumb.Item)`
  a {
    color: ${siteDarkBlue};
  }

  font-weight: bold;
`;

export const StyledBreadcrumb = styled(Breadcrumb)`
  ol {
    margin-bottom: 0;
  }
`;
